// Bootstrap Required
@import "../../node_modules/bootstrap/scss/functions";
@import "../../node_modules/bootstrap/scss/mixins";
@import "../../node_modules/bootstrap/scss/variables";

@import url('https://fonts.googleapis.com/css?family=Montserrat');
@import url('https://fonts.googleapis.com/css?family=Montserrat:700');

// Variables
$tisa_primary: #314159;
$tisa_secondary: #F07F3B;

/* $tisa_button_background: #00AEB2;
$tisa_button_text: #fff;
$tisa_button_hover: #F07F3B; */

$btn-primary-color: #fff;
$btn-primary-bg:#F07F3B;

$theme-colors: (
    "primary": $tisa_primary,
    "secondary": $tisa_secondary
);

//$font-family-base: "Myriad Pro", Myriad, "Trebuchet MS", Helvetica,
//$font-family-sans-serif;

$font-family-base: Montserrat;

$transition-base: all 0.2s ease;
$transition-fade: opacity 0.1s ease;
$transition-collapse: height 0.1s ease;

$spacer: 1.2rem;
$enable-rounded: true;
$enable-shadows: false;
$enable-gradients: true;
$enable-transitions: true;
$enable-grid-classes: true;
$enable-caret: true;
$enable-print-styles: false;

$fa-font-path: "../../node_modules/@fortawesome/fontawesome-free/webfonts";
$fa-font-size-base: $font-size-base;

// Bootstrap Complete
@import "../../node_modules/bootstrap/scss/functions";
@import "../../node_modules/bootstrap/scss/variables";
@import "../../node_modules/bootstrap/scss/mixins";
@import "../../node_modules/bootstrap/scss/root";
@import "../../node_modules/bootstrap/scss/reboot";
@import "../../node_modules/bootstrap/scss/type";
@import "../../node_modules/bootstrap/scss/images";
//@import "../../node_modules/bootstrap/scss/code";
@import "../../node_modules/bootstrap/scss/grid";
//@import "../../node_modules/bootstrap/scss/tables";
//@import "../../node_modules/bootstrap/scss/forms";
@import "../../node_modules/bootstrap/scss/buttons";
@import "../../node_modules/bootstrap/scss/transitions";
@import "../../node_modules/bootstrap/scss/dropdown";
//@import "../../node_modules/bootstrap/scss/button-group";
//@import "../../node_modules/bootstrap/scss/input-group";
//@import "../../node_modules/bootstrap/scss/custom-forms";
@import "../../node_modules/bootstrap/scss/nav";
@import "../../node_modules/bootstrap/scss/navbar";
@import "../../node_modules/bootstrap/scss/card";
//@import "../../node_modules/bootstrap/scss/breadcrumb";
//@import "../../node_modules/bootstrap/scss/pagination";
//@import "../../node_modules/bootstrap/scss/badge";
//@import "../../node_modules/bootstrap/scss/jumbotron";
@import "../../node_modules/bootstrap/scss/alert";
//@import "../../node_modules/bootstrap/scss/progress";
//@import "../../node_modules/bootstrap/scss/media";
@import "../../node_modules/bootstrap/scss/list-group";
@import "../../node_modules/bootstrap/scss/close";
@import "../../node_modules/bootstrap/scss/modal";
//@import "../../node_modules/bootstrap/scss/tooltip";
//@import "../../node_modules/bootstrap/scss/popover";
@import "../../node_modules/bootstrap/scss/carousel";
@import "../../node_modules/bootstrap/scss/utilities";
@import "../../node_modules/bootstrap/scss/print";
@import "../../plugin/bootstrap-tileslide/scss/tileslide";

// FontAwesome
@import "../../node_modules/@fortawesome/fontawesome-free/scss/variables";
@import "../../node_modules/@fortawesome/fontawesome-free/scss/mixins";
@import "../../node_modules/@fortawesome/fontawesome-free/scss/core";
@import "../../node_modules/@fortawesome/fontawesome-free/scss/larger";
@import "../../node_modules/@fortawesome/fontawesome-free/scss/fixed-width";
@import "../../node_modules/@fortawesome/fontawesome-free/scss/list";
//@import "../../node_modules/@fortawesome/fontawesome-free/scss/bordered-pulled";
//@import "../../node_modules/@fortawesome/fontawesome-free/scss/animated";
//@import "../../node_modules/@fortawesome/fontawesome-free/scss/rotated-flipped";
//@import "../../node_modules/@fortawesome/fontawesome-free/scss/stacked";
@import "../../node_modules/@fortawesome/fontawesome-free/scss/icons";
@import "../../node_modules/@fortawesome/fontawesome-free/scss/screen-reader";
//@import "../../node_modules/@fortawesome/fontawesome-free/scss/brands";
@import "../../node_modules/@fortawesome/fontawesome-free/scss/regular";
@import "../../node_modules/@fortawesome/fontawesome-free/scss/solid";
//@import "../../node_modules/@fortawesome/fontawesome-free/scss/v4-shims";

// Theme Variables
// $header-height: 96px;

// Theme Extentions
//@import "theme/donslide";
@import "theme/text";
