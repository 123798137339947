// Slideshow
#slideshow {
	$slideshow-height-xs: 16rem;
	$slideshow-height-sm: 16rem;
	$slideshow-height-md: 16rem;
	$slideshow-height-lg: 24rem;
	$slideshow-height-xl: 28rem;

	@include media-breakpoint-up(xs) { height: $slideshow-height-xs; }
	@include media-breakpoint-up(sm) { height: $slideshow-height-sm; }
	@include media-breakpoint-up(sm) { height: $slideshow-height-md; }
	@include media-breakpoint-up(lg) { height: $slideshow-height-lg; }
	@include media-breakpoint-up(xl) { height: $slideshow-height-xl; }

	.carousel-indicators li {
		background-color: rgba($color: white, $alpha: 0.5);

		&.active {
			background-color: white;
		}
	}

	.carousel-inner {
		height: 100%;

		.carousel-item {
			height: 100%;

			.carousel-item-image {
				height: 100%;
				object-fit: cover;
				object-position: center center;
			}
		}
	}

	// animtate hero images
	.carousel-item-image-animate {

		$scaleImageStart: 110%;
		$scaleImageEnd: 100%;

		animation-name: scaleImage;
		animation-duration: 6s;
		animation-iteration-count: 1;

		@keyframes scaleImage {
			from   {width: $scaleImageStart; height: $scaleImageStart;}
			to {width: $scaleImageEnd; height: $scaleImageEnd;}
		}
	}

	// animate caption and button
	.carousel-caption-animate {

		$moveCaptionFrom: 10%;
		$moveCaptionTo: 0;

		animation-name: moveCaption;
		animation-duration: 2s;
		position: relative;
		animation-iteration-count: 1;

		@keyframes moveCaption {
			from   {opacity: 0; left: $moveCaptionFrom;}
			to {opacity: 1; left: $moveCaptionTo;}
		}
	}

	.carousel-caption {
		left: 9%;
		bottom: 5%;
		right: unset;
		width: auto;
		max-width: 62%;
		padding: 3% 4%;
		text-align: start;

		background-image: url('../img/final/blur.png');
		background-size: 100% 100%;

		@include media-breakpoint-down(xs) {
			max-width: 82%;
		}

		$caption-font-size: 0.9rem;
		$caption-h-mult: 1.25;

		// added
		$caption-fontweight: 700;

		font-size: $caption-font-size !important;
		h3 { 
			font-size: ($caption-font-size * $caption-h-mult); 
			// added
			font-weight: $caption-fontweight;
		}
		.btn {
			font-size: 0.7em !important;
			@include button-variant($btn-primary-bg, darken($btn-primary-bg, 7.5%), darken($btn-primary-bg, 10%), lighten($btn-primary-bg,5%), lighten($btn-primary-bg, 10%), darken($btn-primary-bg,30%));
			color: $btn-primary-color;
		}

		@include media-breakpoint-up(xs) {
			font-size: ($caption-font-size * 1.1) !important;
			h3 { font-size: (($caption-font-size * $caption-h-mult) * 1.1); }
			// added
			font-weight: $caption-fontweight;
		}

		@include media-breakpoint-up(sm) {
			font-size: ($caption-font-size * 1.2) !important;
			h3 { font-size: (($caption-font-size * $caption-h-mult) * 1.2); }
			// added
			font-weight: $caption-fontweight;
		}

		@include media-breakpoint-up(md) {
			font-size: ($caption-font-size * 1.3) !important;
			h3 { font-size: (($caption-font-size * $caption-h-mult) * 1.3); }
			// added
			font-weight: $caption-fontweight;
		}

		@include media-breakpoint-up(lg) {
			font-size: ($caption-font-size * 1.4) !important;
			h3 { font-size: (($caption-font-size * $caption-h-mult) * 1.4); }
			// added
			font-weight: $caption-fontweight;
		}

		@include media-breakpoint-up(xl) {
			font-size: ($caption-font-size * 1.5) !important;
			h3 { font-size: (($caption-font-size * $caption-h-mult) * 1.5); }
			// added
			font-weight: $caption-fontweight;
		}
	}

}

// Text Field
#textfield {

	.hero-text {
		line-height: 1.5em;
	}

}

// Tileslide
#tileslide {
	$tileslide-height-xs: 16rem;
	$tileslide-height-sm: 15rem;
	$tileslide-height-md: 14rem;
	$tileslide-height-lg: 15rem;
	$tileslide-height-xl: 18rem;
	$tileslide-tile-count: 4;
	$tileslide-zindex-base: 10;

	overflow: hidden;

	@include media-breakpoint-up(xs) { height: $tileslide-height-xs; }
	@include media-breakpoint-up(sm) { height: $tileslide-height-sm; }
	@include media-breakpoint-up(md) { height: $tileslide-height-md; }
	@include media-breakpoint-up(lg) { height: $tileslide-height-lg; }
	@include media-breakpoint-up(xl) { height: $tileslide-height-xl; }


	.tileslide-item {
		height: 100%;
		position: relative;
		box-shadow: 0 0 .4rem $black;


		.tileslide-item-bg {
			position: absolute;
			left: 0; width: 100%; top: 0; height: 100%;
			object-fit: cover;
			object-position: right center;

			@include transition(filter .3s ease-in-out);
		}

		.tileslide-item-content {
			position: absolute;
			bottom: 5%; left: 5%; width: 90%;

			@include transition(filter .3s ease-in-out);

		}

		&.active { .tileslide-item-bg { filter: opacity(.5); } }
		&:not(.active) { .tileslide-item-content { filter: opacity(0); } }

		@include hover {
			.tileslide-item-bg, .tileslide-item-content { @include transition(filter .2s ease-in-out); }
		}
	}

	.tileslide-item {
		@for $i from 1 through $tileslide-tile-count {
			&:nth-child(#{$i}) {
				z-index: ($tileslide-zindex-base + $tileslide-tile-count - $i);
			}
		}
	}

	.tileslide-item .tileslide-item-content {
		$tileslide-font-sizes: (0.8rem 0.9rem 0.8rem 1.0rem 1.2rem);
		$tileslide-font-size-base: 7pt;
		$tileslide-font-size-mult: 0.4vw;
		$tileslide-h-mult: 1.2;

		left: 0;
		bottom: 0;
		right: unset;
		width: auto;
		max-width: 100%;
		padding: 2%;

		background-image: url('../img/final/blur.png');
		background-size: 100% 100%;

		font-size: calc(#{$tileslide-font-size-base} + #{$tileslide-font-size-mult}) !important;
		h3 { font-size: calc(#{$tileslide-font-size-base * $tileslide-h-mult} + #{$tileslide-font-size-mult * $tileslide-h-mult}); }
		h3 { margin-top: 1.5em !important; margin-bottom: 0.2em !important; }
		ul li { margin: -0.2em 0 !important; }
	}
}