
// Impressum
#kontakt {
    input {
        width: 100%;
        height: 2em;
    }

    textarea {
        width: 100%;
    }

    button {
        width: 200px;
    }
}

