// Notes on the classes:
//

$tileslide-transition: flex-grow .3s ease-in-out;
$tileslide-hov-transition: flex-grow .2s ease-in-out;

.tileslide {
  display: flex;
  align-items: stretch;
}

.tileslide-item {
  flex-grow: 1;

  @include transition($tileslide-transition);
}

.tileslide {
  @include hover {

    .tileslide-item {
      @include transition($tileslide-hov-transition);
    }
  }
}
