
// Variables
$header-height-shrink: 56px !default;

// Sidebar
#sidebar {

	.sidebar-navigation {
		position: sticky;
		top: $header-height-shrink;
		font-size: 0.9rem;

		.list-group-item {
			transition: color 200ms ease-out;

			&:not(.active) + .list-group {
				display: none;
			}
		}
	}
}
