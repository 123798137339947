
// Datenschutz
#datenschutz {

	h3 {
		padding-top: ($spacer * 0.8);
		padding-bottom: ($spacer * 0.4);
		font-size: 1.4rem;
	}

	h4 {
		padding-top: ($spacer * 0.4);
		padding-bottom: ($spacer * 0.2);
		font-size: 1.2rem;
	}
}
