
// Variables
$header-height-normal: 112px !default;
$header-height-shrink: 56px !default;
$header-logo-height-normal: 72px !default;
$header-logo-height-shrink: 48px !default;
$navbar-margin-subtract: 12px;

// Header
#header {
	position: fixed;
	top: 0; left: 0; width: 100%;
	z-index: $zindex-fixed;

	@include transition(margin-bottom .3s ease-in-out);

	.header-logo {
		height: $header-logo-height-normal;
		margin-top: (($header-height-normal - $header-logo-height-normal) / 2 - $navbar-margin-subtract);
		margin-bottom: (($header-height-normal - $header-logo-height-normal) / 2 - $navbar-margin-subtract);

		@include transition(all .3s ease-in-out);
	}

	&.shrink .header-logo {
		height: $header-logo-height-shrink;
		margin-top: (($header-height-shrink - $header-logo-height-shrink) / 2 - $navbar-margin-subtract);
		margin-bottom: (($header-height-shrink - $header-logo-height-shrink) / 2 - $navbar-margin-subtract);
	}

	#header-menu-button {
		.fas, .far { &:before { content: fa-content($fa-var-times); } }
		&.collapsed { .fas, .far { &:before { content: fa-content($fa-var-bars); } } }
	}

	#header-menu {
		.nav-item.dropdown {
			& > .dropdown-menu { margin-top: 0; }

			@include hover {
				& > .dropdown-menu { display: block; }
			}
		}

		.nav-link {
			color: theme-color('dark');

			@include hover {
				color: theme-color('secondary');
			}
		}
	}
}

// Header Space Reservation
#header--space-reservation {
	height: $header-height-normal;
}
