
// Various Definitions
$header-height-normal: 112px;
$header-height-shrink: 56px;
$header-logo-height-normal: 72px;
$header-logo-height-shrink: 48px;

// Theme
@import "theme";

// Style
@import "module/cookie";
@import "module/footer";
@import "module/header";
@import "module/image-modal";
@import "module/sidebar";
@import "page/datenschutz";
@import "page/impressum";
@import "page/index";
@import "page/kontakt";

// General Definitions
.anchor-link {
	display: block;
	height: $header-height-normal;
	margin-top: -$header-height-normal;
	visibility: hidden;
}
.cursor-pointer {
	cursor: pointer;
}
